import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";

import Home from './components/Home';
import Work from './components/Work';
import Press from "./components/Press";
import Experience from "./components/Experience";
import ShowWork from './components/ShowWork';

function Header() {

    return (
        <Router>
            <header>
                <div className="container">
                    <div className="top-nav">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="logo-wrapper">
                                    <div className="logo">
                                        <NavLink to="/">Sidra Salman</NavLink>
                                    </div>
                                    <div className="res-menu-icon">
                                        <button className="menu-icon">
                                            <i className="fa fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="top-nav-bar">
                                    <button className="menu-close">&times;</button>
                                    <ul className="list-unstyled">
                                        <li>
                                            <NavLink activeClassName="active" exact to="/">About</NavLink>
                                        </li>
                                        <li><NavLink activeClassName="active" to="/work">Work</NavLink></li>
                                        <li><NavLink activeClassName="active" to="/press">Press</NavLink></li>
                                        <li><NavLink activeClassName="active" to="/experience">Experience</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                
                <Route path="/work/:work">
                    <ShowWork />
                </Route>
                <Route path="/work">
                    <Work />
                </Route>
                <Route path="/press">
                    <Press />
                </Route>
                <Route path="/experience">
                    <Experience />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
                
                {/* <Route path="/press">
                    <Press />
                </Route> */}
            </Switch>
        </Router>
    )
}

export default Header;