import React from 'react';
import Helmet from 'react-helmet';

function Home() {
    return (
        <section>
            <Helmet>
                <title>Home</title>
                <link rel="canonical" href="https://sidrasalman.com/" />
            </Helmet>
            <div className="container">
                <div className="main-content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content-img">
                                <img src="/assets/images/content-img-1.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <strong>Amazonian | Design & CX Enthusiast | Creative Director | See It Be It Alumni | Pitch 100 Women 2020</strong>
                                {/* <strong>Creative Director | See It Be It Alumni | Pitch 100 Women 2020 | Dentsu Aegis Network</strong> */}
                                {/* <p>Sidra Salman is a passionate advertising professional, a digital geek in spare time and a yogi on most Sundays. She believes in utilizing the collective power of her profession, her passion and her gender in creating meaningful narratives for the brands she works for. With over 12 years of advertising experience, and a track record of creating effective integrated communications, she is on a journey to engineer positive change in culture, behaviour and human lives.</p> */}
                                <p>Data-driven creative and communication specialist navigating the dynamic shifts in tech, AI, and evolving costumer behaviors and preferences.</p>
                                <p>Bringing over a decade of advertising industry experience, Sidra has worked with some of the most prestigious ad networks such as Saatchi & Saatchi, MullenLowe Group, and Dentsu. She has handled a diverse portfolio of clients throughout her career, including but not limited to Unilever, Roche, Standard Chartered, Procter and Gamble and Phillip Morris. Currently, as Senior Art Director at Amazon Imaging Services, she spearheads the European Hardlines (HL) portfolio, cultivating creativity and innovation through strategic prowess.</p>
                                <p>Sidra is an active women empowerment advocate and often share her perspective and experiences through her Instagram blogs and LinkedIn articles to inspire women in their journey towards financial and social independence. She is an Alumna of Spikes Asia's #SeeItBeIt - a prestigious career acceleration program for women in creative fields. She was also selected as one of Pitch Franzine's Superwomen in 2020.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;