import React from 'react';
import Helmet from 'react-helmet';
import Moment from 'react-moment';

function Experience() {
    return (
        <section>
            <Helmet>
                <title>Experience</title>
                <link rel="canonical" href="https://sidrasalman.com/experience" />
            </Helmet>
            <div className="container">
                <div className="experiance-wrapper">
                    <h3>EXPERIENCE</h3>

                    <h5>Amazon - <Moment durationFromNow interval={1000} date="2023-01-01"></Moment></h5>
                    <p><strong>Sr. Art Director (Imaging, Europe)</strong> January 2023 - Present</p>
                    <p>Overseeing creative direction of the European Hardlines portfolio at Amazon Imaging Services. Leveraging consumer insights to enhance customer satisfaction. Collaborating with cross-functional teams to optimize user experience (UX). Executing compelling visual communication strategies in alignment with Amazon's brand ethos and tailored to the specific needs of the European market.</p>
                    <hr />
                    {/* <h5>Synergy Group - <Moment durationFromNow interval={1000} date="2021-06-01"></Moment></h5> */}
                    <h5>Synergy Group (Synite Digital & Synergy Dentsu.)</h5>
                    <p><strong>Creative Director</strong> June 2021 - October 2022</p>
                    <p>Led the integration of creative and strategic departments to establish Synergy Group's one-window operations. This integrated approach now offers clients a comprehensive 360-degree communications solution, effectively harmonizing strategic imperatives with creative excellence for maximum impact.</p>
                    {/* <p><strong>Creative Director</strong> June 2021 - Present</p>
                    <p>Leading the consolidation of teams and portfolio to build Synergy Group's one window operations for clients seeking 360 communications solutions.</p> */}
                    <br />

                    <h5>Synite Digital</h5>
                    <p><strong>Creative Director</strong> October 2019 - June 2021</p>
                    <p>Spearheaded a 36-member creative team distributed across three cities. Successfully managed a diverse portfolio of 24 active brands, leading impactful campaigns including Standard Chartered's #SheStandsToChallenge, Roche's #Blurfie, #SuzukiAGS, and #KFCFeelGood within the last two years. As head of Synite Digital's core function, played a pivotal role in driving business growth, fostering creative innovation, and ensuring talent retention, even in the challenging Covid-affected business landscape.</p>
                    <br />

                    <p><strong>Associate Creative Director, </strong> November 2017 - October 2019</p>
                    <p>Excelled in assembling and steering top-tier creative teams across three offices — Karachi, Lahore, and Islamabad. Instrumental in securing significant victories for key clients including KFC, Roche Pakistan, Haier, Dominos, and Palmolive Naturals, enhancing Synite Digital's creative stature</p>
                    <br />

                    <p><strong>Creative Group Head,</strong> October 2015 - November 2017</p>
                    <p>Hired to establish the creative department and reshape Synite's image from a digital media house to a full-service agency. Commenced with a modest fivebrand portfolio and a creative team of three. Within two years, grew the portfolio to 15 creative-led accounts, nurturing a team of nine exceptional professionals. Instrumental in securing initial successes through pitch-winning projects and disruptive campaigns such as Olper's Cream - Born to Bake, Omore's Moments of Happiness, and Palmolive's #AsNaturalsAs (a campaign subsequently featured as a Google case study for contextual advertising). </p>
                    <hr />

                    <h5>MullenLowe Group</h5>
                    <p><strong>Creative Group Head</strong> December 2013 - September 2015</p>
                    <p>Led the Unilever Portfolio, managing prominent brands including Fair & Lovely, Lifebuoy Soap, and Cornetto. Secured the PAS (Pakistan Advertisers' Society) Award for the impactful 'Hik Piyali Pyar' campaign for Brooke Bond Pearl Dust.</p>
                    <hr />

                    <h5>Creative Chaos</h5>
                    <p><strong>Creative Manager</strong> April 2013 - December 2013</p>
                    <p>Contributed to launching numerous brands on the digital medium, including the pioneering telecommunications account Jazz (formerly known as Mobilink). Played a pivotal role in the success of Creative Chaos as a pioneering digital agency in the country. </p>
                    <hr />

                    <h5>IAL Saatchi &amp; Saatchi</h5>
                    <p><strong>Creative Manager </strong> March 2012 - March 2013</p>
                    <p>As Creative Manager at IAL Saatchi & Saatchi, served as a dedicated resource on P&G accounts, contributing to the success of category leaders such as Head & Shoulders, Safeguard, and Pampers. </p>
                    <hr />

                    <h5>DesignMatters</h5>
                    <p><strong>Graphic Designer </strong> January 2010 - February 2012</p>
                    <p>Started as a Junior Designer, progressed to the role of Creative Executive at Design Matters. Maintained a consistent presence on one of the largest automobile accounts, Toyota Pakistan, for a span of two years. </p>
                    <hr />
                    <h5>Adcom Leo Burnett</h5>
                    <p><strong>Creative Intern </strong> June 2009 - December 2009</p>
                    <p>Interned at Adcom, gaining valuable exposure to the fast-paced mainstream advertising culture. Learned under the guidance of an incredible creative team that provided inspiration and prepared me for future career challenges. </p>
                    <hr />
                    <h5>TEACHING EXPERIENCE</h5>
                    <p><strong>SZABIST - Visiting Faculty</strong></p>
                    <p>Digital Advertising February 2017 - June 2017</p>
                    <hr />
                    <h5>EDUCATION</h5>
                    <p><strong>Visual Studies Department,</strong> University of Karachi</p>
                    <p>Bachelors in Communication Design 2006 - 2009</p>
                    <p>Thesis Distinction - 2009</p>
                </div>
            </div>
        </section>
    )
}

export default Experience;