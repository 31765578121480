import React from 'react';
import {
    Link
} from "react-router-dom";

import works from './data/works';

function Work() {

    return (
        <section className="work-wrapper">
            <div className="container-fluid px-md-5">
                <div className="row">
                    {works.map((work) => {
                        return(
                            <div className="col-md-6" key={work.slug}>
                                <div className="work-block">
                                    <div className="work-img">
                                        <Link to={`work/${work.slug}`}>
                                            <img src={work.featured_image} alt={work.title} className="img-fluid" />
                                            <div className="work-content">
                                                <h4>
                                                    <Link to={`work/${work.slug}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: work.title }} /></Link>
                                                </h4>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Work;