import React from 'react';
import Helmet from 'react-helmet';

function Press() {
    return (
        <section>
            <Helmet>
                <title>Press</title>
                <link rel="canonical" href="https://sidrasalman.com/press" />
            </Helmet>
            <div className="container">
                <div className="awards-wrapper">
                    <h3>PRESS</h3>
                    <ul className="list-unstyled press-lists">
                        <li className="media">
                            <img src="https://www.brandsynario.com/wp-content/uploads/2022/03/sidra.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Sidra Salman - Creative Director, Synergy Group</h5>
                                <p>The struggle for women empowerment is always on. There is a long journey ahead of us with so many battles along the way that it often gets overwhelming. However, I keep myself motivated for the cause by picking one battle at a time. Currently, my entire focus is towards making my department a safe and conducive professional space for working mothers...</p>
                                <a href="https://www.brandsynario.com/team/sidra-salman/" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>

                        <li className="media">
                            <img src="https://i.dawn.com/large/2022/02/621c5fd052f2c.png" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">What Women In Advertising Want In 2022</h5>
                                <p>Here is a little fact that everyone in the industry knows, but no one talks about. The marketing and advertising industry bigwigs have developed and supported a toxic late sitting culture for years. If you can hang out in the office until late, are available on WhatsApp 24/7 and do not have a life other than your professional commitments, then there is a clear growth track for you...</p>
                                <a href="https://aurora.dawn.com/news/1144368" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                        
                        <li className="media">
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">What Women In Advertising Want In 2022</h5>
                                <p>One of the best things about Downton Abbey is the independence the Crawley women gradually achieve and Robert’s willing acceptance of it – especially Edith taking ownership of her magazine by becoming the editor. TV dramas apart, there is no denying that despite a global workforce still heavily dominated by men...</p>
                                <a href="https://aurora.dawn.com/news/1144402" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>

                        <li className="media">
                            <img src="https://i.dawn.com/large/2022/01/61db499301cb7.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Aurora's Talking Heads 2021 - Agency Heads</h5>
                                <p>Aurora polled Pakistan's leading agency heads on the impact on Pakistan's communications industry of the current squeeze on marketing budgets, the increasing role of client procurement departments in dictating how these budgets are spent, and the reality that the creative space is no longer the preserve of the traditional creative agency.</p>
                                <a href="https://aurora.dawn.com/news/1144282" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>

                        <li className="media">
                            <img src="https://synergyzer.com/wp-content/uploads/2021/05/ad-star-jury.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Creative Director Sidra Salman Joins Ad Stars 2021 As A Juror</h5>
                                <p>A true achiever knows no boundaries or gender. All it takes is perseverance, dedication, and a different perspective from the rest of the world. In an illustrious career, Synite Digital's Creative Director, Sidra Salman has added another accolade to her strong list of achievements by becoming a member of the jury in the Ad Stars 2021.</p>
                                <a href="https://synergyzer.com/creative-director-sidra-salman-joins-ad-stars-2021-as-a-juror/" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>

                        <li className="media">
                            <img src="https://synergyzer.com/wp-content/uploads/2020/07/sidrah-salman-357x420.png" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Is the Creative Director Role Headed for Extinction? CD's and ECD's Give Insights</h5>
                                <p>The pace of digital advertising has given birth to more linear structures versus the vertical structures of traditional advertising. How and into what will the Creative Director position evolve?</p>
                                <a href="https://synergyzer.com/creative-director-role-headed-for-extinction-creative-and-executive-creative-director-give-insights/" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>

                        <li className="media">
                            <img src="https://www.thenews.com.pk/assets/uploads/magazine/2021-03-05/799573_7387526_happy_magazine.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Those Who Chose To Challenge</h5>
                                <p>A challenged world is an alert world and from challenge comes change. On the occasion of International Women's Day, this week You! highlights a few stories of women in leadership roles and how they persevered during the pandemic…</p>
                                <a href="https://www.thenews.com.pk/magazine/you/799573-those-who-chose-to-challenge" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                        <li className="media">
                            <img src="https://i.dawn.com/primary/2020/06/5ee9e6193af53.png" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Sidra Salman: Pitch Superwoman 2020</h5>
                                <p>ZEENAT CHAUDHARY: What are the criteria required to make it to the Pitch 100 Superwomen list? SIDRA SALMAN: The Pitch 100 Superwomen list was an initiative taken in 2017 by Sherry Collins, founder of Pitch Fanzine, a movement that started in 2015 to "challenge the inequality, discrimination and lack of diversity in the creative industries, while simultaneously showcasing outstanding, contemporary talent in Pitch magazine."</p>
                                <a href="https://aurora.dawn.com/news/1143769" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                        <li className="media">
                            <img src="https://i.dawn.com/primary/2020/01/5e0d7e05785e5.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">See it, be it, do it</h5>
                                <p>
                                    As I wait at Esquires Coffee on a quiet Saturday morning, browsing through my phone, I receive a call from Sidra Salman, who (as I look up) is hesitantly looking at me, standing about three feet away, casually dressed in white and turquoise.
                                </p>
                                <a href="https://aurora.dawn.com/news/1143632" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                        <li className="media">
                            <img src="https://www.brandsynario.com/wp-content/uploads/2019/09/IMG-20190926-WA0006.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Sidra Salman makes it to ”Spikes Asia See it Be It 2019″</h5>
                                <p>
                                    Spikes Asia, the Asia Pacific Festival of Creativity is the region's most prestigious honours for the creative branded communications industry.
                                </p>
                                <a href="https://www.brandsynario.com/sidra-salman-makes-it-to-spikes-asia-see-it-be-it-2019/" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                        <li className="media">
                            <img src="https://brandinginasia.com/wp-content/uploads/2019/09/See-it-be-it-Spikes-Asia-2019-Branding-in-Asia-Magazine.jpg" className="mr-3" alt="" style={{maxWidth: "20%"}} />
                            <div className="media-body">
                                <h5 className="mt-0 mb-1">Spikes Asia Reveals 10 Finalist for ‘See It Be It' Career Acceleration Program</h5>
                                <p>
                                    Spikes Asia has revealed the names of the 10 finalists selected to take part in the festival's first “See It Be It” career acceleration program for future female leaders.
                                </p>
                                <a href="https://brandinginasia.com/spikes-asia-reveals-10-finalist-for-see-it-be-it-career-acceleration-program/" target="_blank" rel="noopener noreferrer">Read more...</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Press;