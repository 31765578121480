import React from 'react';
import Moment from 'react-moment';

function Footer() {
    return (
        <section>
            <div className="container">
                {/* <div className="contact-btn">
                    <a href="#">Contact</a>
                </div> */}
                <div className="social-links text-center">
                    <div className="icon d-inline-block">
                        <a href="https://www.facebook.com/sidra.salman01"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.linkedin.com/in/sidra-salman-b849ab49/"><i className="fab fa-linkedin-in"></i></a>
                        <a href="https://www.instagram.com/sidrah.salman/?hl=en"><i className="fab fa-instagram"></i></a>
                    </div>

                    <p className="copyright">&copy; 2021-<Moment format="YY" /> <a href="https://sidrasalman.com">Sidra Salman</a>. All rights reserved.<br />
                        Developed by <a href="https://www.linkedin.com/in/webwizo/" target="_blank" rel="noopener noreferrer">Asif Iqbal</a></p>
                </div>
            </div>
        </section>
    )
}

export default Footer;