import React from 'react';
import Helmet from 'react-helmet';
import { NavLink, useParams } from "react-router-dom";

import works from './data/works';

function ShowWork() {

    let { work } = useParams();

    const detail = works.filter((x) => x.slug === work)[0];
    const sanitizeTitle = detail.title.replace('<br>', ' - ')

    return (

        <section>
            <Helmet>
                <title>{sanitizeTitle}</title>
                <link rel="canonical" href={`https://sidrasalman.com/work/${detail.slug}`} />
            </Helmet>
            <div className="container">
                <div className="women-wrapper text-center">
                    <h2>{sanitizeTitle}</h2>
                    <div dangerouslySetInnerHTML={{ __html: detail.content }} />
                </div>
                <div className="women-video text-center">
                    {detail.videos.map((video, index) => {
                        return (
                            <div key={index.toString()} className="videoWrapper"><iframe title={detail.slug} width="560" height="315" src={`https://www.youtube.com/embed/${video}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe></div>
                        )
                    })
                    }
                </div>
                <br/>
                <div className="women-video text-center">
                    {detail.images.map((image, index) => {
                        return (
                            <img key={index.toString()} className="work-image img-fluid" src={`/assets/work/images/${image}`} alt={sanitizeTitle} />
                        )
                    })
                    }
                </div>
                <div className="women-img text-center">
                    <strong>Client:</strong> {detail.client} <br />
                    <strong>Type:</strong> {detail.type} <br />
                    <strong>Published:</strong> {detail.published_at}
                </div>
                <div className="footer-nav">
                    <NavLink exact to="/work">All</NavLink>
                    {works.map((row, index) => {
                        return (
                            <NavLink key={index} onClick={window.scrollTo(0, 0)} activeClassName="active" to={`/work/${row.slug}`}>{row.title.replace('<br>', ' - ')}</NavLink>
                        )
                    })}
                </div>
            </div>
        </section>
        // <section>

        //     <div className="container">
        //         <div className="row">
        //             <div className="col-md-12">
        //                 <h2>{work}</h2>
        //             </div>
        //         </div>

        //         <div className="row">
        //             <div className="col-md-12">
        //                 <nav className="index-navigation">
        //                     <ul className="list-inline">
        //                         <li className="list-inline-item"><NavLink exact to="/work">All</NavLink></li>
        //                         {works.map((row) => {
        //                             return (
        //                                 <li className="list-inline-item index-link-delimiter-type-slash"><NavLink activeClassName="active" to={`/work/${row.slug}`}>{row.title}</NavLink></li>
        //                             )
        //                         })}
        //                     </ul>
        //                 </nav>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default ShowWork;